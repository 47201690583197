try {
  imageMapResize();
} catch {
  console.log("please connect to internet");
}

// start handeling
const images = document.querySelectorAll("[data-anime-image]");
images.forEach((image) => {
  let mapImage = document.getElementById(image.dataset.animeImage);
  let interval = setInterval(function () {
    image.classList.toggle("anime-image-filter-animation");
  }, 3000);

  mapImage.addEventListener("click", (e) => {
    e.preventDefault();
  });

  mapImage.addEventListener("mouseenter", function () {
    clearInterval(interval);
    image.classList.remove("anime-image-filter-animation");
    image.classList.add("anime-image-hover");
  });

  mapImage.addEventListener("mouseleave", function () {
    image.classList.remove("anime-image-hover");
    interval = setInterval(function () {
      image.classList.toggle("anime-image-filter-animation");
    }, 3000);
  });

  // mobile
  mapImage.addEventListener("touchstart", () => {
    clearInterval(interval);
    image.classList.remove("anime-image-filter-animation");
    image.classList.add("anime-image-hover");
  });

  mapImage.addEventListener("touchend", () => {
    image.classList.remove("anime-image-hover");
    interval = setInterval(function () {
      image.classList.toggle("anime-image-filter-animation");
    }, 3000);
  });
});
