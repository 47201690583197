const menuItems = document.querySelectorAll("[data-menu-item]");
const mainHeader = document.getElementById("main-header");
menuItems.forEach((element) => {
  const targetElement = document.getElementById(element.dataset.menuItem);
  element.addEventListener("click", (e) => {
    e.preventDefault();
    window.scrollTo({
      top:
        targetElement.getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        mainHeader.clientHeight -
        20,
    });
  });
});

// initial moving
const url = new URL(window.location.href);
const section = url.searchParams.get("section");

// if has section params
if (section) {
  setTimeout(function () {
    const targetElement = document.getElementById(section);
    window.scrollTo({
      top:
        targetElement.getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        mainHeader.clientHeight -
        20,
    });
  }, 400);
}
