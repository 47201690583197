// first initialize
const names = [
    { text: "", status: "text" },
    { text: "", status: "text" },
    { text: "", status: "text" },
    { text: "2020-Q3", status: "setion" },
    { text: "Create the idea", status: "text" },
    { text: "Make MVP team", status: "text" },
    { text: "Design & Develop Software", status: "text" },
    { text: "", status: "text" },
    { text: "", status: "text" },
    { text: "2020-Q4", status: "setion" },
    { text: "Firs test", status: "text" },
    { text: "Fix bugs", status: "text" },
    { text: "Marketing", status: "text" },
    { text: "Advertisement", status: "text" },
    { text: "", status: "text" },
    { text: "", status: "text" },
    { text: "2021-Q1", status: "setion" },
    { text: "Feedback & Reports", status: "text" },
    { text: "", status: "text" },
    { text: "", status: "text" },
    { text: "2021-Q2", status: "setion" },
    { text: "MVP To Fully Fledged Product", status: "text" },
    { text: "Extend the Team", status: "text" },
    { text: "", status: "text" },
    { text: "", status: "text" },
    { text: "2021-Q3", status: "setion" },
    { text: "UX & UI - Buyer & Seller App", status: "text" },
    { text: "UX & UI - Website", status: "text" },
    { text: "Whitepaper", status: "text" },
    { text: "", status: "text" },
    { text: "", status: "text" },
    { text: "2021-Q4", status: "setion" },
    { text: "Social media", status: "text" },
    { text: "Implement Website Code", status: "text" },
    { text: "Make LVC Token", status: "text" },
    { text: "", status: "text" },
    { text: "", status: "text" },
    { text: "", status: "text" },
];
const yearSetion = "#5D6D5B";
const yearText = "#6D6D75";

// varibles
const mainCanvas = document.querySelector(".canvas-roadmap-position");
const canvas = document.getElementById("myCanvas");
const btnWatchAgain = document.getElementById("btn-watch-again");
const roadmapWeaper = document.getElementById("roadmap-rapper");
const roadmapWeaperInner = document.getElementById("roadmap-rapper-inner");

let fontSizeHandel = 3;
let heightWillLose;
let interval;

// control speed and moving line
let stepAutoplay;
let stepWidthBack;
function controlMoving() {
    stepAutoplay = screen.width / 400;
    if (screen.width <= 400) {
        stepWidthBack = 2;
        heightWillLose = mainCanvas.offsetHeight / 1.2;
    } else if (screen.width > 400 && screen.width <= 700) {
        stepWidthBack = 2;
        heightWillLose = mainCanvas.offsetHeight / 1.3;
    } else if (screen.width > 700 && screen.width <= 1200) {
        stepWidthBack = 13;
        heightWillLose = mainCanvas.offsetHeight / 1.45;
    } else if (screen.width > 1200) {
        stepWidthBack = 25;
        heightWillLose = mainCanvas.offsetHeight / 1.4;
    }
}

// window onload action
window.onload = function () {
    controlMoving();
    // canvas setting
    let screenHeight = mainCanvas.offsetHeight - heightWillLose;
    let screenHeightPart = fontSizeHandel / screenHeight;
    canvas.width = mainCanvas.offsetWidth;
    canvas.height = screenHeight * names.length;
    const ctx = canvas.getContext("2d");
    ctx.textAlign = "right";

    // handel drawing
    names.forEach((name, index) => {
        // font
        ctx.font = `${
            fontSizeHandel * (index + 1)
        }vw american-sign, Roboto, sans-serif`;
        // color
        if (name.status == "setion") {
            ctx.fillStyle = yearSetion;
        } else {
            ctx.fillStyle = yearText;
        }
        // do drawing
        ctx.fillText(
            name.text,
            mainCanvas.offsetWidth - stepWidthBack,
            screenHeight * (index + 1)
        );
    });

    // canvas scrolling (core of work) -> user can not scroll this but he can scroll the wraper
    mainCanvas.addEventListener("scroll", () => {
        // clean canvas
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        // handel drawing
        names.forEach((name, index) => {
            let fontSize =
                fontSizeHandel * (index + 1) -
                screenHeightPart * mainCanvas.scrollTop;
            if (fontSize > 0) {
                // font
                ctx.font = `${fontSize}vw american-sign, Roboto, sans-serif`;
                // color
                if (name.status == "setion") {
                    ctx.fillStyle = yearSetion;
                } else {
                    ctx.fillStyle = yearText;
                }
                // do drawing
                ctx.fillText(
                    name.text,
                    mainCanvas.offsetWidth - stepWidthBack,
                    screenHeight * (index + 1)
                );
            }
        });
    });

    // auto play handel
    function autoMoveRoadmap() {
        interval = setInterval(
            function () {
                mainCanvas.scrollTo({
                    top: mainCanvas.scrollTop + stepAutoplay,
                });
            },

            20
        );
    }
    // resize control
    window.addEventListener("resize", () => {
        controlMoving();
        screenHeight = mainCanvas.offsetHeight - heightWillLose;
        screenHeightPart = fontSizeHandel / screenHeight;

        canvas.width = mainCanvas.offsetWidth;
        canvas.height = screenHeight * names.length;
        ctx.textAlign = "right";
    });

    // handel scroll form first
    function startFromZreo() {
        mainCanvas.scrollTo({
            top: 0,
        });
        roadmapWeaper.scrollTo({
            top: 1500000,
        });
    }
    startFromZreo();

    // handel start autoplay after scrolling to roadmap
    function handelWindowScroll() {
        if (
            window.scrollY + window.innerHeight >
                mainCanvas.getBoundingClientRect().top -
                    document.body.getBoundingClientRect().top &&
            window.scrollY <
                mainCanvas.getBoundingClientRect().top -
                    document.body.getBoundingClientRect().top
        ) {
            autoMoveRoadmap();
            window.removeEventListener("scroll", handelWindowScroll);
        }
    }

    window.addEventListener("scroll", handelWindowScroll);
    handelWindowScroll();

    // handel wraper roadmap
    let roadmapWeaperScrolled = roadmapWeaper.scrollTop;
    roadmapWeaper.addEventListener("scroll", () => {
        mainCanvas.scrollTo({
            top:
                mainCanvas.scrollTop +
                (roadmapWeaper.scrollTop > roadmapWeaperScrolled
                    ? stepAutoplay * 4
                    : stepAutoplay * -4),
        });
        roadmapWeaperScrolled = roadmapWeaper.scrollTop;
    });

    // handel die canvas after scroll to end
    mainCanvas.addEventListener("scroll", () => {
        if (
            mainCanvas.scrollTop + screenHeight + heightWillLose >
            canvas.scrollHeight - 10
        ) {
            mainCanvas.classList.add("invisible");
            roadmapWeaper.classList.add("invisible");
            btnWatchAgain.classList.remove("hidden");
        }
    });
    // watch again button handel
    btnWatchAgain.addEventListener("click", () => {
        btnWatchAgain.classList.add("hidden");
        mainCanvas.classList.remove("invisible");
        roadmapWeaper.classList.remove("invisible");
        startFromZreo();
    });

    // handel mouse action
    roadmapWeaper.addEventListener("mouseenter", () => {
        clearInterval(interval);
    });

    roadmapWeaper.addEventListener("mouseleave", () => {
        autoMoveRoadmap();
    });

    // handel touch action
    roadmapWeaper.addEventListener("touchstart", () => {
        clearInterval(interval);
    });

    roadmapWeaper.addEventListener("touchend", () => {
        autoMoveRoadmap();
    });
};
