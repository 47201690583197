const animetext_elements = Array.from(
  document.querySelectorAll("[data-animetext]")
);

function handel() {
  if (animetext_elements.length === 0) {
    window.removeEventListener("scroll", handel);
  }
  animetext_elements.forEach((element, index) => {
    if (
      window.scrollY + window.innerHeight >
        element.getBoundingClientRect().top -
          document.body.getBoundingClientRect().top &&
      window.scrollY <
        element.getBoundingClientRect().top -
          document.body.getBoundingClientRect().top
    ) {
      switch (element.dataset.animetext) {
        case "top":
          element.style.transform = "translateY(0px)";
          animetext_elements.splice(index, 1);
          break;

        case "right":
        case "left":
          element.style.transform = "translateX(0px)";
          animetext_elements.splice(index, 1);
          break;
      }
    }
  });
}

window.addEventListener("scroll", handel);

window.addEventListener("load", handel);
