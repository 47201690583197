// varibles
const headerMenu = document.getElementById("header-menu");
const headerNameSite = document.getElementById("header-name-site");
const headerNameSiteContent = document.getElementById(
  "header-name-site-content"
);
const headerBanner = document.getElementById("header-banner");
const headerLiviciImg = document.getElementById("header-livici-img");
// load page handel
window.addEventListener("load", handelMenuAnimationLoadWindow);
function handelMenuAnimationLoadWindow() {
  if (window.scrollY > 0) {
    accessDoing = false;
    headerMenu.classList.add("-mt-8");
    headerNameSite.classList.add("w-0", "translate-y-1");
    headerNameSiteContent.classList.add("pl-4.5", "text-sm");
    headerLiviciImg.classList.add("-translate-y-3/4", "h-16");
    headerBanner.classList.replace(
      "header-livici-banner-first",
      "header-livici-banner-secound"
    );
  }
}
// scroll handel
let accessDoing = !(window.scrollY > 0);
window.addEventListener("scroll", handelMenuAnimation);
function handelMenuAnimation() {
  if (window.scrollY > 0) {
    if (accessDoing) {
      accessDoing = false;
      headerMenu.classList.add("-mt-8");
      headerNameSite.classList.add("w-0", "translate-y-1");
      headerNameSiteContent.classList.add("pl-4.5", "text-sm");
      headerLiviciImg.classList.add("-translate-y-3/4", "h-16");
      headerBanner.classList.replace(
        "header-livici-banner-first",
        "header-livici-banner-secound"
      );
    }
  } else if (!accessDoing) {
    accessDoing = true;
    headerMenu.classList.remove("-mt-8");
    headerNameSite.classList.remove("translate-y-1", "w-0");
    headerNameSiteContent.classList.remove("pl-4.5", "text-sm");
    headerLiviciImg.classList.remove("-translate-y-3/4", "h-16");
    headerBanner.classList.replace(
      "header-livici-banner-secound",
      "header-livici-banner-first"
    );
  }
}
